.bd-gutter {
  --bs-gutter-x: var(--bd-gutter-x);
}

.bd-layout {

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 1fr 5fr;
    gap: $grid-gutter-width;
  }
}

.bd-sidebar {
  grid-area: sidebar;
}

.bd-main {
  grid-area: main;
  padding: 18px;
  @include media-breakpoint-down(lg) {
    max-width: 760px;
    margin-inline: auto;
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-areas:
      "intro"
      "toc"
      "content";
    grid-template-rows: auto auto 1fr;
    gap: inherit;
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      "intro   toc"
      "content toc";
    grid-template-rows: auto 1fr;
    grid-template-columns: 4fr 1fr;
  }

  &.no-toc{

    @include media-breakpoint-up(lg) {
      grid-template-areas:
        "intro   toc"
        "content content" !important;
      grid-template-rows: auto 1fr;
      grid-template-columns: 4fr 1fr;
    }
  }
}

.bd-intro {
  grid-area: intro;
}

.bd-toc {
  grid-area: toc;
}

.bd-content {
  grid-area: content;
  min-width: 1px; // Fix width when bd-content contains a `<pre>` https://github.com/twbs/bootstrap/issues/25410
}
