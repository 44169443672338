// Style based on Furo https://github.com/pradyunsg/furo

.admonition{
  margin: 1rem auto;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  background: $color-admonition-background;

  border-radius: 0.375rem;

  overflow: hidden;
  page-break-inside: avoid;

  // First element should have no margin, since the title has it.
  > :nth-child(2) {
    margin-top: 0;
  }
  // Last item should have no margin, since we'll control that w/ padding
  > :last-child {
    margin-bottom: 0;
  }
}
.admonition-title{
  position: relative;
  margin-bottom: 0.5rem;

  font-weight: 900;
  line-height: 1.3;

    // Our fancy icon
  &::before {
    content: "";
    position: absolute;
    left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
  // Helps vertically align the SVG icon because it's smaller height than the font
  img, svg {
    margin-bottom: 3px;
    margin-right: 3px;
  }

}

// Default styles
.admonition-title {
  background-color: var(--color-admonition-title-background);
  &::before {
    background-color: var(--color-admonition-title);
    mask-image: var(--icon-admonition-default);
    mask-repeat: no-repeat;
  }
}

//
// Variants
//

.admonition {
  border-radius: 0.375rem;
  border: 1px solid #{fog-0};
  opacity: 1;
  background-color: #{fog-0};

  &.note {
    border: 1px solid var(--hint-note);
    background-color: var(--hint-note-background);
  }

  &.important {
    border: 1px solid var(--hint-important);
    background-color: var(--hint-important-background);
  }

  &.warning {
    border: 1px solid var(--hint-warning);
    background-color: var(--hint-warning-background);
  }

  &.tip {
    border: 1px solid var(--hint-tip);
    background-color: var(--hint-tip-background);
  }

}