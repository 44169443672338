//
// Footer
//

.bd-footer {
  background: $navbar-background-color;

  p {
    color: $navbar-link-color;
    margin-bottom: $navbar-link-font-size;
    text-align: left;

    a {
      color: #{$aqua-500};
    }
  }

  .links-col{
    text-align: left !important;
    justify-content: space-evenly !important;
  }

  .footer-link {
    color: $navbar-link-color;
    font-size: $navbar-link-font-size;
    opacity: .85;
    letter-spacing: 1.1px;
    text-decoration: none;

    &:hover {
      opacity: 1.5;
      color: $navbar-link-color-hover;
      text-decoration: none;
    }
  }

  .crossplane-footer {
    color: transparent;
    width: 152px;
    height: auto;
  }

  .cncf-footer {
    color: transparent;
    width: 233px;
    height: auto;
    margin-bottom: 24px;
  }

  @include media-breakpoint-down(lg){

    text-align: left !important;
    padding-left: 0 !important;

    .container {
      padding-left: 1.5rem !important;
      margin-left: 0 !important;

      .row {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      }
    }

    .img-col{
      order: 1 !important;
      padding-bottom: 2.5rem !important;
    }

    .links-col{
      order: 2 !important;
      margin-left: 0 !important;
      padding-left: 1rem !important;

      .row .col-lg {
        margin-left: 0 !important;
        padding-left: 0 !important;
        margin-bottom: 1rem !important;
        padding-bottom: 0 !important;
      }
    }

    .copyright-col{
      order: 4 !important;
      margin-left: 0 !important;
      padding-left: 1rem !important;
      border-right: 0px !important;
    }

    .cncf-col {
      order: 3 !important;
      margin-left: 0 !important;
      padding-left: 1rem !important;
    }

    // .icons {
    //   white-space: nowrap;
    //   flex-direction: column;
    //   padding-right: 0 !important;

    //   .slack-icon,
    //   .github-icon {
    //     padding-left: 0 !important;
    //     padding-right: 0 !important;
    //     padding-top: 0 !important;
    //     padding-bottom: 0 !important;
    //   }
    // }

    // .color-switcher {
    //   .form-check {
    //     white-space: nowrap;
    //     flex-direction: column;
    //     padding-right: 0 !important;

    //     .form-check-input {
    //       padding-bottom: 2px;
    //     }

    //     .form-check-label {
    //       padding-right: 0px !important;
    //       padding-left: 0px !important;
    //     }
    //   }
    // }
  }

}
